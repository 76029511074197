Backbone.Marionette.Controller.Dialog = Backbone.Marionette.Controller.extend({

    constructor: function(options){
        options = options || {};
        this.region = options.region || new Backbone.Marionette.Region.Dialog;
        Backbone.Marionette.Controller.Dialog.__super__.constructor.call(this, options);

    },

    show:function(view, options){
        options = options || {};
        options.title = options.title || '';
        extraClass = options.extraClass || '';
        className = 'modal' + extraClass;
        var modal = new Backbone.Marionette.LayoutView.Modal({ mainView : view, title : options.title, className: className});
        _.defaults(options,{
            region : this.region,
        });
        this.setMainView(modal);
        this._manageView(modal, options);
        view.on('destroy', modal.destroyModal, modal);
        modal.showModal();
    },

    setMainView: function(view){
        if(this._mainView){
            return;
        }
        this._mainView = view;
    },

    _manageView: function(view, options){
        options.region.show(view);
    },

});