Backbone.Marionette.LayoutView.SmallModal = Backbone.Marionette.LayoutView.extend({

    className: 'modal small',
    template: JST['small_modal.jst'],
    

    regions: {
        primaryRegion: {
            selector: "#main_region",
            regionClass: Backbone.Marionette.Region.Panning,
        },
        secondayRegion : '.secondary-region',
        footerRegion : '#footerRegion',
    },

    onShow: function(){
        var view = this;
        this.$el.one('hide', function(){
            view.mainView.trigger('modal:hidden');
        })

        this.mainView.trigger('modal:shown');
    },  

    initialize: function(options){
        console.log('working')
        this.mainView = options.mainView;
        this.mainView.once('modal:destroy' , this.destroyModal, this);
    

        if( this.options.css ){
            this.$el.addClass(this.options.css);
        };

    },


    serializeData: function(){
        var data = Marionette.LayoutView.prototype.serializeData.apply(this, arguments);
        data['title'] = this.options.title;
        return data;
    },


    destroyModal: function(){
        this.$el.modal('hide');
    },

    showModal: function(){
        this.$el.modal('show');
    },

    setTitle: function(title){
        this.$el.find('#myModalLabel').text(title);
    },

    onRender: function(){

        this.primaryRegion.show(this.mainView);


    }



});