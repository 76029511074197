
	Backbone.View.BasePieChart = Backbone.View.BaseChartView.extend({

		createChart: function(){
			this.chart = new google.visualization.PieChart( document.getElementById( this.options.containerId ) );

		},

		default_options: {
			pieHole: 0.4,
			legend: {
					position: 'none',
				},
			enableInteractivity: false,
			height: 300,
			width: 300,	
			chartArea:{
				top: 0,
				left: 0,
				width: 300,
				height: 300,
			},
				
		},


		render: function(){
			this.chart.draw(this.data_view, this.default_options);
			return this;
		},
	});


