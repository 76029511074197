this["JST"] = this["JST"] || {};

this["JST"]["main_page_layout.jst"] = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '\n\n<a href="#" style="display: none;" id="back-btn" class="back-btn"><i class="fa fa-chevron-left"></i>Back</a>\n\n<div class="page-btns" id="js_page_btn_region">\n	<!-- page buttons here -->\n</div>\n	\n<h2 class="page-title" id="js_page_title"></h2>\n\n<div id="js_toolbar_region">\n	<!-- page toolbar here -->\n</div>\n\n<div id="js_content_region">\n	<!-- page content here -->\n</div>';

}
return __p
};

this["JST"]["modal.jst"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '		<div class="modal-dialog  modal-lg">\n			<div class="modal-content">\n				<div class="modal-header">\n				 ';
 if(!static)  { ;
__p += '\n				    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n				 ';
 } ;
__p += '\n				    <h3 class="modal-title" id="myModalLabel">' +
((__t = ( title )) == null ? '' : __t) +
'</h3>\n				</div>\n				<div class="modal-body">\n					\n						<div id="js_spinner"></div>\n						<div class="main-region"></div>\n					\n				</div>\n		\n			</div> <!-- /.modal-content -->\n		</div> <!-- /.modal-dialog -->\n	</script>\n\n<!-- <div class="modal-footer" id="footerRegion">\n\n</div> -->';

}
return __p
};

this["JST"]["generic_modal.jst"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- Used for create shipment workflow and other actions -->\n<div class="modal-dialog modal-lg">\n  <div class="modal-content">\n    <div class="modal-header centre">\n      ';
 if(showBackBtn){ ;
__p += '\n      <button id="js-modal-back-btn" class="btn btn-default"><i class="fa fa-chevron-left"></i>Back</button>\n      ';
 } ;
__p += '\n\n      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n      <h4 class="modal-title" id="myModalLabel">' +
((__t = ( title )) == null ? '' : __t) +
'</h4>\n    </div>\n    <div class="modal-body">\n      <div class="alert-container"></div>\n      <div id="js_spinner"></div>\n      <div class="main-region"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};

this["JST"]["small_modal.jst"] = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n    <h3 id="myModalLabel">' +
((__t = ( title )) == null ? '' : __t) +
'</h3>\n</div>\n<div class="modal-body" id="main_region">		\n\n</div>\n';

}
return __p
};

this["JST"]["tooltip_modal.jst"] = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="main_region">\n\n</div>';

}
return __p
};