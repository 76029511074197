Backbone.Marionette.LayoutView.Modal = Backbone.Marionette.LayoutView.extend({

    template: JST['generic_modal.jst'],

    modalTitle : '',

    className : 'modal',

    regions: {
        primaryRegion: {
            selector: ".main-region",
            regionClass: Backbone.Marionette.Region.Panning,
        },
        secondayRegion : '.secondary-region',
        footerRegion : '#footerRegion',
    },

    onShow: function(){
        var view = this;
        this.$el.one('hide', function(){
            view.mainView.trigger('modal:hidden');
        });
    },

    initialize: function(options){

        this.mainView = options.mainView;
        this.mainView.once('modal:destroy' , this.destroyModal, this);
        this.modalTitle = options.title;
        this.static = options.static || false;
        this.showBackBtn = options.showBackBtn || false;
        this.mainView.on('destroy:secondary:view',function(){
            this.destroySeondary();
        }, this);

        this.mainView.on('show:secondary:view', this.loadSecondary, this);



    },

    loadSecondary: function(view){

        this.oldview = this.primaryRegion.currentView;
        this.primaryRegion.transitionToView(view, 'slide');

    },

    destroySeondary: function(){
        this.oldview.$el.css('opacity', 1);
        this.primaryRegion.transitionToView(this.oldview, 'slide-back');
        this.oldview.delegateEvents();
    },

    serializeData: function(){
        var data = Marionette.LayoutView.prototype.serializeData.apply(this, arguments);
        data.title = this.modalTitle;
        data.static = this.static;
        data.showBackBtn = this.showBackBtn;
        return data;
    },


    attributes: function(){
        return {
            // 'data-width' : 860,
            // 'data-height' : 450,
            // 'data-maxHeight' : 450,
            'tabindex': '-1',
            'role': 'dialog',
            'aria-labelledby': 'myModalLabel',
            'aria-hidden': 'true',
        };
    },

    destroyModal: function(){
        this.$el.modal('hide');
    },

    showModal: function(){
        this.$el.modal('show');
    },

    onRender: function(){
        if(this.options.footerRegion){
            this.footerRegion.show( this.options.footerRegion );
        }
        this.primaryRegion.show(this.mainView);

        this.secondayRegion.on('show', function(){
            this.primaryRegion.$el.hide();
        }, this);

        this.secondayRegion.on('destroy', function(){
                if(this.primaryRegion){
                    this.primaryRegion.$el.show();
                }
        }, this);
        var modal = this;
        this.primaryRegion.on('show', function(){
            this.primaryRegion.currentView.once('modal:destroy', function(){
                modal.destroyModal();
            });
        },this);

    }



});