

Backbone.PagePromptView = Backbone.View.extend({

	el: '#js_overlay_prompt',
	events: {
		'click #js_destroy_prompt': 'destroyPrompt',
	},

	destroyPrompt: function(){
		console.log('clicked');
		this.$el.hide();
		$('.page_content').removeClass('overlay-blur');
	},
});

