Backbone.Marionette.LayoutView.TooltipModal = Backbone.Marionette.LayoutView.extend({

    className: 'tooltip_modal',
    template: JST['tooltip_modal.jst'],
    
    regions: {
        primaryRegion: {
            selector: "#main_region",
 
        },

    },

    onShow: function(){
        var view = this;
    },  

    initialize: function(options){
        this.mainView = options.mainView;
    },

    onRender: function(){
        this.primaryRegion.show(this.mainView);
    }



});