

	Backbone.View.BaseChartView = Backbone.Marionette.ItemView.extend({


		initialize: function(){


			// if( this.options.chart_options ){
			// 	$.extend( this.default_options, this.options.chart_options );
			// };


		},


		formatValueLabels: function(value, valueText, valueAxis){
	 		if( value >= 1000 ){
	 			var value = value / 1000;
	 			var valueText = value.toString()
	 			valueText = '£' + valueText + 'K';
	 			return valueText;

	 		} else {
	 			return '£' + value;
	 		}
	 	},

	 	// Displays empty chart message
	 	showEmptyChartMessage: function(chart) {
	 		// set min/max on the value axis

	        // chart.valueAxes[0].minimum = 0;
	        // chart.valueAxes[0].maximum = 100;
	        
	        // add dummy data point
	        var dataPoint = {
	            dummyValue: 0
	        };
	        dataPoint[chart.categoryField] = '';
	        chart.dataProvider = [dataPoint];
	        
	        // add label
	        chart.addLabel(0, '50%', 'The chart contains no data', 'center');
	        
	        // set opacity of the chart div
	        chart.chartDiv.style.opacity = 1;
	        
	        // redraw it
	        chart.validateNow();


	        var jchartDiv = $(chart.chartDiv); //convert to jquery
	        jchartDiv.append('<div class="chart-nodata-bg"><div class="chart-nodata-' + chart.type + '"></div></div>');
	 	},


	 	// Checks data exists on fields passed in 'fieldToCheckEmpty' array
	 	hasEmptyData: function(chart, fieldsToCheckEmpty) {

	 		var arrayLength = chart.dataProvider.length;
	 		var chartDataArray = chart.dataProvider;
	 		var isEmpty = false;

	 		// Iterate dataset
	 		for (var i = 0; i < arrayLength; i++) {

	 			// For each field name in fieldToCheckEmpty array
	 			for (var j = 0; j < fieldsToCheckEmpty.length; j++) {

	 				// Check field exists on array object
		 			if (fieldsToCheckEmpty[j] in chartDataArray[i]){
		 				// console.log('field exists');

		 				// If not empty mark isEmpty
		 				if (chartDataArray[i][fieldsToCheckEmpty[j]] == 0) {
		 					isEmpty = true;
		 					// console.log('field is empty');
		 				}
		 			}
	 			}
	 			// console.log(JSON.stringify(chartDataArray[i]));
	 		}

	 		return isEmpty;
	 	},

	});





	// Backbone.View.BaseChartView = Backbone.Marionette.ItemView.extend({


	// 	initialize: function(){

	// 		// if( Analytics.config.channels == 0 ){

	// 		// 	var containerId = '#' + this.options.containerId;

	// 		// 	this.render = function(){
	// 		// 		var template = $('#js_line_chart_blank');
	// 		// 		$(containerId).html("<div class='blank line-chart'><i class='ui circular signal icon'></i></div>");
	// 		// 	};

	// 		// }



	// 		if( this.options.chart_options ){
	// 			$.extend( this.default_options, this.options.chart_options );
	// 		};


	// 	},

	// 	render: function(){
	// 		console.log('render');

	// 		this.createChart();

	// 		var data_view = this.data_view;

	// 		this.chart.draw(data_view, this.default_options);
	
	// 	},

	// });
