// TODO: Remove all of this, pretty sure it's deprecated and related to a legacy
// drop down menu that is no longer used.
$(document).ready(function() {

	var rootLink = $('.root-section');

	if(rootLink.hasClass('active')){
		$('.root-section.active').next().css('display', 'block').addClass('open');
	};


	rootLink.click(function(e){
		e.preventDefault();
		var childNav = $(this).next();

		if( childNav.hasClass('open') ){
			$(this).removeClass('active');
			childNav.slideUp(160, 'easeInOutCubic').removeClass('open');

		} else {
			$('.open').slideUp(160, 'easeInOutCubic').removeClass('open');
			$('.active').removeClass('active');
			childNav.slideDown(160, 'easeInOutCubic').addClass('open');
			$(this).addClass('active');
		};

	});
});