Backbone.Collection.InfinitePagination = Backbone.Collection.extend({
  initialize: function() {
    _.bindAll(this, 'parse', 'url', 'pageInfo', 'nextPage', 'previousPage', 'filtrate', 'sort_by');
    var options = options || {};
    this.limit = this.limit || 20;
    this.offset = this.offset || 0;
    this.filter_options = this.filter_options || {};
    this.sort_field = this.sort_field || '';
    this.on('reset', function(){
      this.offset = 0;
    }, this);
    // typeof(options) != 'undefined' || (options = {});
    // typeof(this.limit) != 'undefined' || (this.limit = 20);
    // typeof(this.offset) != 'undefined' || (this.offset = 0);
    // typeof(this.filter_options) != 'undefined' || (this.filter_options = {});
    // typeof(this.sort_field) != 'undefined' || (this.sort_field = '');
    this.on('destroy', function(){
      
    }, this);


  },
  fetch: function(options) {
    options = options || {};
    //this.trigger("fetching");
    var self = this;
    var success = options.success;
    options.success = function(resp) {
      //self.trigger("fetched");
      if(success) { success(self, resp); }
    };
    // options.remove = false;
    // console.log(options.remove);
    return Backbone.Collection.prototype.fetch.call(this, options);
  },
  parse: function(resp) {
    this.offset = resp.meta.offset;
    this.limit = resp.meta.limit;
    this.total = resp.meta.total_count;
    return resp.objects;
  },
  url: function() {
      urlparams = {offset: this.offset, limit: this.limit};
      urlparams = $.extend(urlparams, this.filter_options);
      if (this.sort_field) {
          urlparams = $.extend(urlparams, {order_by: this.sort_field});
      }
      return this.baseUrl + '?' + $.param(urlparams);
  },

  add: function(options){
    if(this.length >= this.limit){
       return;
    }
    return Backbone.Collection.prototype.add.call(this, options);
  },
  pageInfo: function() {
    var info = {
      total: this.total,
      offset: this.offset,
      limit: this.limit,
      pages: Math.ceil(this.total / this.limit),
      prev: false,
      next: false,
      length: this.length,
      sort_field : this.sort_field,
    };
 
    // info.length = info.offset -

    var max = Math.min(this.total, this.offset + this.limit);
 
    if (this.total == this.pages * this.limit) {
      max = this.total;
    }

    var currentPage = (this.offset/this.limit)+1;

    var lastPage = Math.ceil(this.total/this.limit);
 
    info.range = [(this.offset + 1), max];

    info.page_list = [ ];

    var page = 0;



    page_range = [];
    
    while (page < lastPage){
      page += 1;
      var range = 5;
      var highRange = range;

      var lowRange = range;

      if(currentPage-range <= 1){
        highRange = (range*2)-(currentPage);
        highRange += 1;
      }



      if(currentPage+range >= lastPage){
        lowRange = (range*2)-(lastPage-currentPage+1);
      }


      if(_.range(currentPage-lowRange, currentPage+highRange, 1).indexOf(page)> -1){
        page_range.push(page);
      }
    }  

    info.page_range = page_range;
    info.lastPage = lastPage;
    info.page = currentPage;
 
    if (this.offset > 0) {
      info.prev = (this.offset - this.limit) || 1;
    }
 
    if (this.offset + this.limit < info.total) {
      info.next = this.offset + this.limit;
    }
 
    return info;
  },

  goToPage: function(number){
    number -= 1;
    this.offset = number*this.limit;
    return this.fetchCopy();
  },

  nextPage: function() {
    if (!this.pageInfo().next) {
      console.log('page:collection:return:false');
      return false;
    }
    this.offset = this.offset + this.limit;
    return this.fetch({remove: false});
  },
  previousPage: function() {
    if (!this.pageInfo().prev) {
      return false;
    }
    this.offset = (this.offset - this.limit) || 0;
    return this.fetchCopy();
  },
  filtrate: function (options) {
      this.filter_options = options || {};
      this.offset = 0;
    return this.fetchCopy();
  },
  clearFilters: function(){
    this.filter_options = {};
    this.offset = 0;
    return this.fetchCopy();
  },
  fetchCopy: function(){
      // depreciated
      var collection = this;
      return collection.fetch();

  },
  sort_by: function (field) {
      
      this.sort_field = field;
      this.offset = 0;
      return this.fetchCopy();
  }
 
});