

	Backbone.View.BaseLineChart = Backbone.View.BaseChartView.extend({

		createChart: function(){

	 		this.chart = new AmCharts.AmSerialChart();
			this.chart.autoMarginOffset = 0;
 			this.chart.marginRight = 0;
 			this.chart.marginBottom = 0;

			this.chart.legend = {
				'valueText': '',
				'markerType': 'square',
				'align': 'right',
				'divId': 'legend_container',
			};

			this.chart.valueAxes = [{
				'axisAlpha': 0,
				'axisColor': '#fff',
				'autoGridCount': false,
				'gridCount': 4,
				'unitPosition': 'left',
				'fontSize': 12,
				'color': '#707070',
				'labelFunction': this.formatValueLabels,
			}];

			this.chart.categoryAxis = {
				'parseDates': true,
				'minPeriod': 'DD',
				'dataDateFormat': "D/M/YY",
				'axisColor': '#C7C7C7',
				'autoGridCount': false,
				'gridColor': '#fff',
				'color': '#fff',
			};

			this.chart.balloon = {
				'animationDuration': 0.0,
				'fadeOutDuration': 0.0,
				'fillAlpha': 1,
				'shadowAlpha': 0,
				"adjustBorderColor": true,
				"cornerRadius": 3,
			};

			this.chart.chartCursor = {
				'cursorColor': '#C7C7C7',
				'cursorAlpha': 0.3,
				'graphBulletAlpha': 0,
				'animationDuration': 0.0,
				'bulletsEnabled': true,
				'bulletSize': 8,
				// 'enabled': false,
	            // "cursorPosition": "mouse",
	            "valueLineEnabled": false,
	            'categoryBalloonColor': '#595959',
	        };


		},




	});

















	// Backbone.View.BaseLineChart = Backbone.View.BaseChartView.extend({

	// 	initialize: function(){
	// 		var view = this;

	// 		var updateLayout = _.debounce(function(e) {
 //                view.render(view.data_view);
 //            }, 500);

 //            $(window).on("resize",function(){
 //            	updateLayout();
 //            });
	// 	},

	// 	destroy: function(){
	// 		$(window).off('resize');
	// 	},

	// 	createChart: function(){
	// 		this.chart = new google.visualization.AreaChart( document.getElementById( this.options.containerId ) );

	// 	},

	// 	default_options: {
	// 		areaOpacity: 0.1,
	// 	    chartArea:{
	// 			top: 20,
	// 			left: 0,
	// 			width: '100%',
	// 			height: '100%',
	// 		},
	// 	    fontSize: 12,
	// 	    hAxis: {
	// 			textPosition: 'none',
					
	// 		},
	// 		height: 300,
	// 		legend: {
	// 			position: 'none',
	// 		},
	// 		lineWidth: 3,
	// 		pointSize: 8,
			
	// 	    titleTextStyle: { 
	// 			color: '#333',
	// 		  	fontSize: 14,
	// 		},
	// 		vAxis: {
	// 			textPosition: 'in',
	// 			viewWindow:{
	// 				min: 0,
	// 			},
	// 		},
				
	// 	},

	// });









