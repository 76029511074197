var loading = Backbone.LoadingView= Backbone.Marionette.ItemView.extend({

	loadingView : true,

    className: 'spinner',

    tagName : 'span',

	template: _.template(''),

});
