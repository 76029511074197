
Backbone.Marionette.Region.OverlayRegion = Marionette.Region.extend({
    initialize: function(){
        var region = this;
        this.on('show', function(){
            $(window).scrollTop(0);
        });

        this.on('show', function(){
            $('#overlay-region').show();
            Backbone.history.once('route', function(){
                region.destroy();
             });
        });
        this.on('destroy', function(){
            $('#overlay-region').hide();
            Backbone.history.off('route');
        });

    },
});

Backbone.Marionette.Region.OverviewRegion = Marionette.Region.extend({
    initialize: function(){
        var region = this;
        this.on('show', function(){
            $(window).scrollTop(0);
        });

        this.on('show', function(){
            $('#test').show();
            $('.main-region').hide();
            Backbone.history.once('route', function(){
                region.destroy();
             });
        });
        this.on('destroy', function(){
            $('#test').hide();
            $('.main-region').show();
            Backbone.history.off('route');
        });

    },
});
