var PaginatedCollectionStd = Backbone.Collection.extend({
  initialize: function() {
    _.bindAll(this, 'parse', 'url', 'pageInfo', 'nextPage', 'previousPage', 'filtrate', 'sort_by');
    typeof(options) != 'undefined' || (options = {});
    typeof(this.limit) != 'undefined' || (this.limit = 20);
    typeof(this.offset) != 'undefined' || (this.offset = 0);
    typeof(this.filter_options) != 'undefined' || (this.filter_options = {});
    typeof(this.sort_field) != 'undefined' || (this.sort_field = '');
  },
  fetch: function(options) {
    typeof(options) != 'undefined' || (options = {});
    //this.trigger("fetching");
    var self = this;
    var success = options.success;
    options.success = function(resp) {
      //self.trigger("fetched");
      if(success) { success(self, resp); }
    };
    return Backbone.Collection.prototype.fetch.call(this, options);
  },
  parse: function(resp) {
    this.offset = resp.meta.offset;
    this.limit = resp.meta.limit;
    this.total = resp.meta.total_count;
    return resp.objects;
  },
  url: function() {
      urlparams = {offset: this.offset, limit: this.limit};
      urlparams = $.extend(urlparams, this.filter_options);
      if (this.sort_field) {
          urlparams = $.extend(urlparams, {order_by: this.sort_field});
      }
      return this.baseUrl + '?' + $.param(urlparams);
  },


  add: function(options){
    console.log('adding')
    return Backbone.Collection.prototype.add.call(this, options);
  },
  pageInfo: function() {
    var info = {
      total: this.total,
      offset: this.offset,
      limit: this.limit,
      pages: Math.ceil(this.total / this.limit),
      prev: false,
      next: false,
      length: this.length,
      sort_field : this.sort_field,
    };
 
    var max = Math.min(this.total, this.offset + this.limit);
 
    if (this.total == this.pages * this.limit) {
      max = this.total;
    }

    var currentPage = (this.offset/this.limit)+1;

    var lastPage = Math.ceil(this.total/this.limit);
 
    info.range = [(this.offset + 1), max];

    info.page_list = [ ];

    var page = 0



    page_range = []
    
    while (page < lastPage){
      page += 1;
      var range = 5
      var highRange = range

      var lowRange = range

      if(currentPage-range <= 1){
        highRange = (range*2)-(currentPage)
        highRange += 1
      }



      if(currentPage+range >= lastPage){
        var lowRange = (range*2)-(lastPage-currentPage+1)
      }


      if(_.range(currentPage-lowRange, currentPage+highRange, 1).indexOf(page)> -1){
        page_range.push(page)
      }
    }  

    info.page_range = page_range;
    info.lastPage = lastPage;
    info.page = currentPage;
 
    if (this.offset > 0) {
      info.prev = (this.offset - this.limit) || 1;
    }
 
    if (this.offset + this.limit < info.total) {
      info.next = this.offset + this.limit;
    }
 
    return info;
  },

  goToPage: function(number){
    number -= 1
    this.offset = number*this.limit
    return this.fetch();
  },

  nextPage: function() {
    if (!this.pageInfo().next) {
      return false;
    }
    this.offset = this.offset + this.limit;
    return this.fetch();
  },
  previousPage: function() {
    if (!this.pageInfo().prev) {
      return false;
    }
    this.offset = (this.offset - this.limit) || 0;
    return this.fetch();
  },
  filtrate: function (options) {
      this.filter_options = options || {};
      this.offset = 0;
      return this.fetch();
  },
  clearFilters: function(){
    this.filter_options = {};
    this.offset = 0;
    this.reset();
    return this.fetch();
  },
  sort_by: function (field) {
      this.reset();
      this.sort_field = field;
      this.offset = 0;
      var test = new PaginatedCollectionStd({sort_field : field, offset : 0})
      test.fetch();
      console.log('sort by yeah')
      console.log(test.toJSON());
      // this.fetch();
  }
 
});