
ZenUtils.Analytics = {

	_get_context: function(value){

		if(value > 0){
			var fontColour = "green-font",
			sign = "<i class='ui green long arrow up icon'></i>";
		} else {
			var fontColour = "red-font";
			sign = "<i class='ui red long arrow down icon'></i>";
		}

		return{
			fontColour: fontColour,
			sign: sign,
		};

	},


	format_pct_change: function(value){

		// formats a percentage for display in reports
		var context = this._get_context(value);
		var template;

		if(typeof(value) === 'string'){
			console.log(value);
			template = _.template("<span><%= value %></span>");

		} else {
			value = numeral( Math.abs(value) ).format('0,0');
			template = _.template("<span class='<%= fontColour %>'><%= value %>%<%= sign %></span>");
		}


		var html = template({fontColour: context.fontColour, sign: context.sign, value: value });

		return html;
	},


	format_unit_change: function(value){
		// formats a number for display in reports

		var context = this._get_context(value);
		var value = Math.abs(value);
		
		var template = _.template("<span class='<%= fontColour %>'><%= value %><%= sign %></span>");
		var html = template({fontColour: context.fontColour, sign: context.sign, value: value });

		return html;
	},



	format_currency_change: function(value){
		// formats a number for display in reports

		var context = this._get_context(value);
		var currency = company_config.currency_symbol;
		var value = numeral( Math.abs(value) ).format();
		var template = _.template("<span class='<%= fontColour %>'><%= currency %><%= value %><%= sign %></span>");
		var html = template({fontColour: context.fontColour, sign: context.sign, currency: currency, value: value });

		return html;
	},

};