Backbone.Marionette.LayoutView.MainPageLayout = Backbone.Marionette.LayoutView.extend({

	template: JST['main_page_layout.jst'],

  	regions: {
      pageBtnRegion: "#js_page_btn_region",
    	toolbarRegion: "#js_toolbar_region",
    	contentRegion: "#js_content_region",
  	},

  	events: {
  		"click #back-btn": "back",
  	},

  	back: function(e){
      e.preventDefault();
      e.stopPropagation();
  		this.trigger('back');
  	},

  	onRender: function(){
  		this.backBtn = this.$el.find('#back-btn');
  		
  		if( this.options.toolbarRegion ){
  			this.toolbarRegion.show( this.options.toolbarRegion );
  		};

        if( this.options.pageBtnRegion ){
          this.pageBtnRegion.show( this.options.pageBtnRegion );
        };

  		this.contentRegion.show( this.options.contentRegion )

      var title = this.contentRegion.currentView.title ? this.contentRegion.currentView.title : this.options.title;

  		this.setTitle( title ); 


  		if( this.contentRegion.currentView.showBackBtn == true  ){
  			this.backBtn.css('display', 'inline-block');
  		};

      this.options.contentRegion.on('all', function(evt){
        this.trigger(evt)
      }, this)

 		
 
  	},

    onShow: function(){
      this.options.contentRegion.trigger('layout:show')
    },

  	setTitle: function(title){
  		this.$el.find('#js_page_title').text(title);
  	},


});